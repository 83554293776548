.comImg {
    width: 40px;
    margin-left: 10px;
    margin-right: 30px;
}

.comDetails {
    display : flex;
    flex-direction: row;
    align-items : center;
}
