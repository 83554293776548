.projectContainer {
    width: 90%;
    margin: 4% 5%;
    border-radius: 6px;
    padding-bottom: 50px;
    color: white;
    /* background: linear-gradient(to bottom,   #0b3458 ,#0a2c4a56); */
}

.tabStyle{
    /* background-color: rgba(0, 0, 0, 0.371); */
    border-radius: 6px;
    margin-top: 20px;

}

.slider:not(:hover) .projectitem {
    opacity: 0.6;
  }
  

.postDetails {
   display: flex; 
   flex-direction:row; 
   justify-content: space-between;
    margin-top : 5px;
    padding: 10px;
}

.postSection{

}
.postOuter {
    padding: 10px;
}
.tabSection{
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}

.tabSection li{
    background-color: black;
    color: white;
}


.tabContent{
    background-color: rgba(0, 0, 0, 0.371); 
    border-radius: 12px;
}

.projectitem{
    background: linear-gradient(to bottom ,#0a2c4a56,   #0b3458); 
    border-radius: 12px;
    transition: transform 0.3s ease-in-out;
}



.projectdetails{
    padding: 10px;
}

.projectImg {
    height: 180px;
    width : 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.skill {
   
    background : #0c6890b8;
    padding: 3px;
    border-radius : 5px;
    box-sizing: border-box;
    color: white;
    margin: 5px;
    /* display: flex; */
    font-size: 14px;
    text-align: center;
}

.redirect{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 5px;
}

.redirectBtn{
    background : black;
    padding: 5px;
    padding-right: 15px;
    padding-left: 15px;
    color: white;
    border-radius : 5px;
    box-sizing: border-box;
}

.slide{
    padding: 20px;
}

.projectitem:hover {
    transform: scale(1.1); /* Scale up the item on hover */
    z-index: 10;  
  }