
.App {
  /* text-align: center; */
}

html{
  scroll-behavior: smooth;
  max-width: 100%;
  overflow-x: hidden;
}

.navbar {
  background-color: #03131A; /* Change this to your desired color */
  overflow: hidden;
  color: white;
}

.navbar-header {
  margin-top : 1% !important;
  margin-left : 10% !important;
}

.navbar-brand {
   font-weight : bold;
   font-size : 32px;
   font-family :Verdana, Geneva, Tahoma, sans-serif;
   /* font-family :Impact, Haettenschweiler, 'Arial Bold', sans-serif; */
   color: "white";
   letter-spacing: 1px;
   white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 1);
}

.navbar-inverse {
  border-color : #03131A;
}

body{
  overflow-x: hidden;
  max-width: 100%;
  background-color: "#03131A";
}

.navbar-nav li a {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  font-family :Verdana, Geneva, Tahoma, sans-serif;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.fade-in-text {
  animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.socials{
  text-align: center;
  /* margin-top: px; */
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: row; /* Align items horizontally */
  justify-content: space-evenly;
}

/* .social-img
{ 
  width:  50px;
} */

.education-section .wrapper
{
  /* width: 100%; */
  color: #1d1d1f;
  background-color: #fbfbfd;
  height: 100vh;
  /* border-radius: 6px; */
  color: white;
  /* text-align: left; */
  width: 90%;
  /* margin: 4% 5%;
  box-shadow: 0 0 10px 10px rgba(0,0,0,0.3); */
}

.education-section .main-title
{
  font-size: 42px;
  font-weight: "bold";
}

.posts-section .main-title
{
  font-size: 48px;
  font-weight: 600px;
}

.experience-section .main-title
{
  font-size: 48px;
  font-weight: 600px;
}

.skills-section .main-title
{
  font-size: 48px;
  font-weight: 600px;
}

.computer
{
  display: flex; 
  flex-direction : row; 
  justify-content: space-around;
  font-size: 10px;
}

.experience-section .wrapper
{
  /* width: 100%; */
  color: #1d1d1f;
  background-color: #fbfbfd;
  height: 100vh;
  border-radius: 6px;
  color: black;
  text-align: left;
  width: 80%;
  margin: 4% 10%;
  /* box-shadow: 0 0 10px 10px rgba(0,0,0,0.3); */
}

.skills-section .wrapper
{
  width: 100%;
  color: #1d1d1f;
  background-color: #fbfbfd;
  height: 100vh;
}

.projects-section .wrapper
{
  width: 100%;
  color: #1d1d1f;
  background-color: #fbfbfd;
  height: 100vh;
}

.posts-section .wrapper
{
  width: 100%;
  color: #1d1d1f;
  background-color: #fbfbfd;
  height: 100vh;
}

.topnav .icon {
  display: none;
}

/* * {
  box-sizing: border-box;
} */

/* Set a background color */
/* body {
  background-color: #474e5d;
  font-family: Helvetica, sans-serif;
} */

/* The actual timeline (the vertical ruler) */
/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0px auto;
  box-sizing: border-box;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color:  #0b3458;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.containera {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  box-sizing: border-box;
}

/* The circles on the timeline */
.containera::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -12px;
  background-color:  #0b3458;
  border: 4px solid #000000;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
  box-shadow: 0 0 2px 2px rgba(0,0,0,0.2);
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  
  border: medium solid #0b3458;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent  transparent #0a2c4a56;
}

/* .react-tabs__tab:focus {
  outline: none;
  background-color: rgba(0, 0, 0, 0.371);
  color: white;
  border-color: white;
  border-bottom: none;
} */

/* .react-tabs__tab-panel--selected {
  outline: none;
  background-color: rgba(0, 0, 0, 0.371);
  color: white;
  border-color: white;
  border-bottom: none;
} */



.react-tabs__tab--selected {
  outline: none;
  background-color: rgba(0, 0, 0, 0.371) !important;
  color: white  !important;
  border-color: white  !important;
  border-bottom: none  !important;
}

.react-tabs__tab:focus:after{
    background: black !important;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid #0b3458;
  border-width: 10px 10px 10px 0;
  border-color: transparent #0a2c4a56 transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -12px;
  border: 4px solid #000000;
}

/* The actual content */
.timeline-content {
  padding: 20px 30px;
  background: linear-gradient(to bottom,  #0a2c4a56, #0b3458);
  position: relative;
  border-radius: 6px;
  text-align: left;
  color: white;
  box-shadow: 0 0 10px 10px rgba(0,0,0,0.3);
}

.projectitem .wrapper{
  width : 300px;
  background-color: #000000;
}

.timeline-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.timeline-sem{
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 3px;
}

.timeline-course{
  font-size: 17px;
  font-weight: bold;
}

.timeline-title
{
  font-size: 22px;
  font-weight: bold;

}

.timeline-role{
  font-size: 20px;
  font-weight: bold;
}

.computer-skills {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  box-shadow: 0 0 4px 4px rgba(0,0,0,0.3);
  padding: 20px;
}
.computer-skills-web {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border-radius: 6px;
  box-shadow: 0 0 4px 4px rgba(0,0,0,0.3); */
  padding: 20px;
}
.computer-skills-web-child {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;
  text-align: center;
}

.timeline-desc
{
  font-size: 16px;
  word-spacing: 3px;

}
.timeline-titres 
{
  display: flex;

}
.timeline-link {
  font-size: 18px;
  text-align: left;

}

@media screen and (max-width : 900px) {
  /* Make all right containers behave like the left ones */
/* Make sure all circles are at the same spot */
.timeline::after {
  left: 31px;
}


.left::after,
.right::after {
  left: 15px;
}

/* Make all right containers behave like the left ones */
.right {
  left: 0%;
}
.containera {
  width: 100%;
  padding-left: 50px;
  padding-right: 0px;
}


.containera:before {
  left: 40px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  /* border-color: transparent white transparent transparent; */
  border-color: transparent #0a2c4a56 transparent transparent;
}

}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
/* Place the timelime to the left */
.timeline::after {
  left: 31px;
}

.timeline-title{
  font-size: 20px;
}
.timeline-role{
  font-size: 18px;
}


/* Full-width containers */
.containera {
  width: 100%;
  padding-left: 50px;
  padding-right: 0px;
}

/* Make sure that all arrows are pointing leftwards */
.containera:before {
  left: 40px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  /* border-color: transparent white transparent transparent; */
  border-color: transparent #0a2c4a56 transparent transparent;
}

.timeline-content {
  padding: 10px 12px;
}

.computer
{
  flex-direction : column; 
}


.computer-skills {
  flex-direction: row;
  padding: 10px;
  margin: 10px;
}

.computer-skills-web {
  flex-direction: column;
}

.computer-skills-web-child {
  flex-direction: row;
  width: 100%;
}

.timeline-desc {
  font-size: 14px;
}

.timeline-desc ul  {
  padding-inline-start : 10px;
}

/* Make sure all circles are at the same spot */
.left::after,
.right::after {
  left: 15px;
}

/* Make all right containers behave like the left ones */
.right {
  left: 0%;
}


.topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .topnav.responsive .dropdown {float: none;}
  .topnav.responsive .dropdown-content {position: relative;}
  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }




  .slideshow-container {
    width: 83%;
    margin: 0 auto;
    padding: 25px 0;
    overflow: visible;
  }


  
  .navbar-header {
    margin-top : 0% !important;
    margin-left : 0% !important;
  }

  .navbar-brand {
    font-size : 17px;
 }

 .waving-hand {
  font-size: 32px; /* Adjust font size for smaller screens */
}
.fade-in-text {
  margin-left: 10px; /* Adjust margin for smaller screens */
  /* font-size: 22px; Adjust font size for smaller screens */
}

.typewriter h1 {
  font-size: 16px; 
}



}

.section {
  border-bottom: 2px solid #000; /* adjust color and width as needed */
  width: 80%;
  /* margin-left: 10%; */
  content: "";
  /* display: block; */
  height: 1px; 
  background-color: #000; /* adjust color as needed */
  margin: 20px 10%; /* space above and below the line */
}