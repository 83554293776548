.section{
    /* background-color: #FFFFF0; */
   /* height: 100vh; */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10vw;
    margin-right: 10vw;
    color: aliceblue;
    height: 100vh;
    align-items: center;
  }


.hi{
    display : flex;
    align-items: center;
    /* font-size : 72px; */
    font-size : 4.5vw;
    justify-content: left;
    font-weight: bold;
  }
  
  .half{
    padding: 50px;
    /* text-align: center; */
    display: flex;
    color: white;
    flex-direction: row; /* Align items horizontally */
    align-items: center;
    justify-content: space-evenly;
  }
  
  .summary{
    /* margin-right: 10px;
    margin-left: 10px; */
    /* font-size: 30px; */
    font-size: 2vw;
  }
  
  .summarypoints{
    /* margin-right: 10px;
    margin-left: 10px; */
    /* font-size: 25px; */
    font-size: 1.5vw;
  }

  .wavingHand {
    animation: wave 2.1s 0.6s infinite;
    transform-origin: 75% 75%;
    display: inline-block;
  }
  
  .topBlur {
    position: absolute;
    width : 50vw;
    height: 50vh;
    min-width: 350px;
    min-height: 350px;
    top: 128px;
    left : -10vw;
    border-radius: 764px;
    background: rgba(25, 55, 109, 0.4);
    filter: blur(100px);
    z-index : -1;
  }
  
  .profilePic {
    width : 18vw;
    border-radius : 50%;
    background-color : white;
    margin-right: 20px;
  }
  
  .aboutHalf {
    padding: 50px;
    align-items: center;
  }
  
  .socialImg
{ 
  width:  3vw;
  padding: 2px;
  margin: 5px 0;
}

.socialImg:hover {
  transform: scale(1.2);
}


  .bottomBlur {
    position: absolute;
    width : 70vw;
    height: 50vh;
    min-width: 350px;
    min-height: 250px;
    top: 246px;
    right : -25vw;
    border-radius: 764px;
    background: rgba(25, 55, 109, 0.4);
    filter: blur(100px);
    z-index : -1;
  }

  .typewriter h1 {
    color: #fff;
    /* font-family: monospace; */
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid orange;
    font-size: 2vw;
    white-space: nowrap; /* Keeps the content on a single line */

    /* margin: 30px 0; Gives that scrolling effect as the typing happens */
    /* letter-spacing: .15em; Adjust as needed */
    animation: 
      typing 3.5s steps(30, end),
      blink-caret .5s step-end infinite;
  }

  @media screen and (max-width: 1024px) {
    .summary{
       font-size: 18px;
       padding-left: 5px;
       padding-right: 5px;
     }

     .summarypoints{
       font-size: 15px;
       padding-left: 15px;
       padding-right: 5px;
     }

     /* .section{
       flex-direction: column-reverse;
       justify-content: space-evenly;
     }  */

     /* .half{
       margin-top: 100px;
       padding: 0;
     } */
     .aboutHalf{
       padding-left: 5%;
       padding-right: 5%;
     }

     .hi{
       font-size: 30px;
       padding-left: 5px;
       padding-right: 5px;
     }

     .typewriter h1{
       font-size: 15px;
     }

     /* .socialImg
     {
       width: 30px;
     } */


 }



  @media screen and (max-width: 600px) {
     .summary{
        font-size: 18px;
        padding-left: 5px;
        padding-right: 5px;
      }

      .summarypoints{
        font-size: 15px;
        padding-left: 15px;
        padding-right: 5px;
      }

      .section{
        flex-direction: column-reverse;
        justify-content: space-evenly;
      }

      .half{
        margin-top: 100px;
        padding: 0;
      }
      .aboutHalf{
        padding-left: 5%;
        padding-right: 5%;
      }

      .hi{
        font-size: 32px;
        padding-left: 5px;
        padding-right: 5px;
      }

      .typewriter h1{
        font-size: 15px;
      }

      .socialImg
      {
        width: 30px;
      }
      .profilePic{
        width: 80%;
      }

 
  }


  /* DEMO-SPECIFIC STYLES */

  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 60% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange }
  }


  /* styles.css (or your preferred CSS file) */
@keyframes wave {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(16deg);
    }
    20% {
      transform: rotate(-6deg);
    }
    30% {
      transform: rotate(16deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(16deg);
    }
    60% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  