.computerskills {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
    box-shadow: 0 0 4px 4px rgba(0,0,0,0.3);
    text-align: center;
    background: linear-gradient(to bottom,   #0b3458 ,#0a2c4a56);
  }

  .skillImg {
    padding: 15px;
  }

  .skillText {
    font-size: 12px;
    font-weight: 500;
  }

  .skilltype {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .computerskillswebchild {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
    text-align: center;
  }
  

  .computerskillsweb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: linear-gradient(to bottom,   #0b3458 ,#0a2c4a56);
    /* border-radius: 6px;
    box-shadow: 0 0 4px 4px rgba(0,0,0,0.3); */
    padding: 10px;
    border-radius: 12px;
  }

  @media screen and (max-width : 720px) {
    .computerskills {
        flex-direction: row;
    }

    .computerskillsweb
    {
        flex-direction: column;
    }

    .computerskillswebchild {
        flex-direction: row;
        width: 100%;
      }
  }