.section {
    color: white;
    width: "90%";
    margin: 4%;
    border-radius: 12px;
    padding: 30px;
    /* background-color: rgba(0, 0, 0, 0.3); */
}
.educationimage{
    flex : 1;
    text-align: center;
}

.educationdetails {
    flex : 2;
}
.eductionList {
    display: flex;
}
.educationCollege { 
    font-size: 20px;
    font-weight: bold;
}

.educationUni { 
    font-size: 24px;
    font-weight: bold;
}

.educationprogram { 
    font-size: 18px;
    font-weight: bold;
}


.educationgpa { 
    font-size: 18px;
    font-weight:500;
    
}

.uniImg {
    width:  80px;
    margin-bottom: 10px;

}

.educationitem {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    margin-top: 40px;
    padding: 20px;
    border-radius: 20px;
    background: linear-gradient(to right, #0a2c4a, #0a2c4a56);
}

.educationyear{
    font-size: 18px;
}


.topBlur {
    position: absolute;
    width : 50vw;
    height: 50vh;
    min-width: 350px;
    min-height: 350px;
    top: 128px;
    left : -10vw;
    border-radius: 764px;
    background: rgba(25, 55, 109, 0.4);
    filter: blur(100px);
    z-index : -1;
  }

  .desc{
    font-size: 16px;
    font-weight:500;
    flex : 2;
    padding-left: 100px;
  }

  @media screen and (max-width: 600px) {
        
    .educationitem {
        flex-direction: column;
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .section {
        margin: 0;
        padding: 20px;
    }

    .educationCollege { 
        font-size: 16px;
    }
    
    .educationUni { 
        font-size: 20px;

    }

    .educationprogram { 
        font-size: 15px;
    }
    
    .educationyear{
        font-size: 15px;
    }
    
    
    .educationgpa { 
        font-size: 15px;
    }

    .desc{
        font-size: 14px;
        padding-left: 0;
      }
      
      .uniImg {
        width:  40px;
        padding: 2px;
    }
  }