.slideshow-container {
    width: 80%;
    margin: 0 auto;
    padding: 40px 0;
  }
  
  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ddd;
    height: 300px;
    border-radius: 4px;
    width: 200px;
  }

  