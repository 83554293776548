.projectContainer {
    width: 90%;
    margin: 4% 5%;
    border-radius: 6px;
    padding-bottom: 50px;
    color: white;
    /* background: linear-gradient(to bottom,   #0b3458 ,#0a2c4a56); */
}

.tabStyle{
    /* background-color: rgba(0, 0, 0, 0.371); */
    border-radius: 6px;
    margin-top: 20px;
      border-color: white;
    border-width: 1px;
}

.tabSection{
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    border-color: white;
    border-width: 1px;
    padding-inline-start: 0px;
    margin-bottom: 0;
}


.tabSection 

/* .react-tabs__tab:focus {
    outline: none;
    background-color: white;
    color: white;
} */

.react-tabs__tab--selected {
    /* background-color: white;
    color: black;
    border: none; */
  }
  

  .tabSection li{
    background-color:white;
    color: black;
    border-color: black;
    border-width: 1px;
    border-radius: 0px;
  }
.tabSection li:first-child {

    border-radius: 0;
    border-top-left-radius: 5px; 
  }


  .tabSection li:last-child {
    border-radius: 0;
    border-top-right-radius: 5px; 
  }
 
  .react-tabs__tab--selected {
    border-bottom: none; /* Remove the bottom border */
  }
.tabContent{
    background-color: rgba(0, 0, 0, 0.371); 
    border-radius: 12px;
    border-color: white;
    border-width: 1px;
}

.projectitem{
    background: linear-gradient(to bottom ,#0a2c4a56,   #0b3458); 
    border-radius: 12px;
    transition: transform 0.3s ease-in-out;
}

.projectdetails{
    padding: 10px;
}

.projectitem:hover {
    transform: scale(1.1); /* Scale up the item on hover */
    z-index: 10;  
  }

.projectImg {
    height: 180px;
    width : 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.skill {
   
    background : #0c6890b8;
    padding: 5px;
    border-radius : 5px;
    box-sizing: border-box;
    margin: 5px;
    font-size: 14px;
    text-align: center;
}



.redirect{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 15px;
}

.redirectBtn{
    background : black;
    padding: 5px;
    color: white;
    border-radius : 5px;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
}

.slide{
    padding: 20px;
}